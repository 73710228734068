
.loginPage {
    width: 100%;
    vertical-align: middle;
    padding-top:5vh ;
}

.logoButtomText{
    margin-top: 5px;
    font-family: Arial;
    font-size: 30px;
}
.buttomImage{
    /*background-image: url("./image/img_2.png");*/
    background-size:cover;
    height: 60vh;
}

.topImage{
    /*background-image: url("./image/img_1.png");*/
    background-position: center;
    background-size:cover;
    width: 100%;
    height: 40vh;
}
.textLogin1{
    font-size: 35px;
    color: aliceblue;
    font-family: Inter;
}
.textLogin2{
    font-family: Inter;
    color: aliceblue;
    font-size: 15px;
}
 a{
    text-decoration: none;
}
.active{
    background-color: white !important;
    color: black !important;
 }
.passwordConfirmation{

}
.my-code{
    horiz-align: center;
}