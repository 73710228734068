.buttonPdf {
  border-radius: 10px;
  border: #fc0f0f solid 1px;
  color: #fc0f0f;
  padding: 5px;
  background-color: white;
}

.buttonExcel {
  padding: 5px;
  border-radius: 10px;
  border: #0b7203 solid 1px;
  color: #0b7203;
  background-color: white;
}

.buttonInfo {
  padding: 5px;
  background-color: white;
  border-radius: 10px;
  border: #0d6efd solid 1px;
  color: #0d6efd;
}

.rows {
  width: 100%;
  padding: 10px;
  background-color: white;
  margin-top: 5px;
}

.list-style {
  &::-webkit-scrollbar {
    height: 10px;
  }
  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #F08D34;
    border-radius: 10px;
  }
  overflow: auto;
  padding: 2px;
  .style-card {
  min-width: 350px;
    margin-top: 0.5px;
    padding: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 3px;
    border: #9AA6AC solid 0.3px;
  }
}
.myInput{
  min-width: 40px;
  max-width: 60px;
  border: #badff1 solid 0.3px;
}
.payBottomLine{
  border-bottom: 0.5px solid #dbddde;
}
.payStyle{
  border-radius: 20px;
}
