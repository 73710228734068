.myBgColor{
  background-color: #F08D34;
}
.color-wh{
  color: white;
}
.bgColor-wh{
  background-color: white;
}

.myColor{
  color: #F08D34;
}

.tabsInActive{
  padding: 10px;
  background-color: #f1f1f1;
  color: #F08D34;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 20px;
  cursor: pointer;
}
.upLine{
  border-top: 1px solid #d4d6d7;
}
.tabsActive{
  border: white solid 1px;
  padding: 10px;
  border-radius: 20px;
  text-align: center;
  background-color:#F08D34;
  color: #f1f1f1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

@media only screen and (min-width: 600px) {
  .tabsActive{
    height: 3rem;
    width: 8rem;
    font-size: 14px;
    font-weight: bolder;
  }
  .tabsInActive{
    height: 3rem;
    width: 8rem;
    font-size: 14px;
    font-weight: bolder;
  }
}
@media only screen and (max-width: 600px) {
  .tabsActive{
    height: 3rem;
    width: 6rem;
    font-size: 14px;
  }
  .tabsInActive{
    height: 3rem;
    width: 6rem;
    font-size: 14px;
  }
}
