
@media (min-width: 768px) {
   .main-body{
       margin-left: 51px;
   }
}
.my-hover:hover{
    background-color: #d3d1d1;
}
.d-relative{

}
