.myDarkButtons{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vh;
  background-color: white;
  border: black solid 0.5px;
  border-radius: 8px;

}
.myDarkButtons2{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 5vh;
  color: white;
  background-color: #00A859;
  border: black solid 0.5px;
  border-radius: 8px;

}